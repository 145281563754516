import { create } from 'zustand';
// import { getAuthData, removeItem, setItem } from '../helpers/localStorage';
import authService from './LoginService';
import LoginService from './LoginService';
import useAlertReducer from './AlertReducer';
import history from '../../../history';
import subscriptionReducer from '../../Subscription/reducer/subscriptionReducer';

export const getAuthData = () => {
  let isLoggedIn = false;
  if (
    localStorage.getItem('accessToken') &&
    localStorage.getItem('accessToken').length
  ) {
    isLoggedIn = true;
  }
  return {
    isLoggedIn,
  };
};
export const setItem = (key, value) => localStorage.setItem(key, value);
export const getItem = (key) => localStorage.getItem(key);
export const removeItem = (key) => localStorage.removeItem(key);

const { isLoggedIn } = getAuthData();

const useLoginReducer = create((set) => ({
  authData: null,
  isLoginLoading: false,
  isGoogleLoading: false,
  isLoggedIn,
  errorMessage: '',
  successMessage: '',
  profileData: null,
  forgetPwdLoader: false,
  forgetData: null,
  resetPwdData: null,
  resetPwdLoader: false,
  successMessageReset: '',
  forgetPwdLoader: false,
  isLogout:false,
  googleLogin: async () => {
    try {
      set({ isGoogleLoading: true, errorMessage: '' });
      // const { data } = await authService.googleLoginValidate(token, tokenType);
      // const authData = data.data;
      // setItem('authData', JSON.stringify(authData));
      // setItem('accessToken', data?.accessToken);
      set({ isLoggedIn: true, isGoogleLoading: false });
      const { success } = useAlertReducer.getState();
      const { profile } = subscriptionReducer.getState();
      // history.push('/home');
      // success(data && data.message);
      success('Login Success');
      profile();
    } catch (err) {
      const { error } = useAlertReducer.getState();
      error(err?.response?.data?.message ?? err.message);
      set({
        errorMessage: err?.message,
        isGoogleLoading: false,
      });
    }
  },
  authLogin: async (formData) => {
    try {
      set({ isLoginLoading: true });
      // console.log('formData', formData);
      // const { data } = await authService.login(formData);
      // const authData = data.user;
      // setItem('accessToken', data?.token?.accessToken);
      // setItem('authData', JSON.stringify(authData));
      set({ isLoggedIn: true, isLoginLoading: false });
      const { success } = useAlertReducer.getState();
      const { profile } = subscriptionReducer.getState();
      // history.push('/home');
      // success(data && data.message);
      success('Login Success');
      profile();
    } catch (err) {
      const { error } = useAlertReducer.getState();
      error(err?.response?.data?.message ?? err.message);
      set({
        errorMessage: err?.response?.data?.message ?? err?.message,
        isLoginLoading: false,
      });
    }
  },
  logout: async () => {
    set({
      userProfile: null,
      authData: null,
      successMessage: '',
      isLoggedIn: false,
      errorMessage: null,
      isLogout:true
    });
    // await useAuthReducer.getState().addLoginHistory(lh_ip, lh_action);
    removeItem('accessToken');
    removeItem('authData');
    // removeItem('refreshToken');
  },

  forgotPassword: async (email) => {
    try {
      set({ forgetPwdLoader: true });
      const { success } = useAlertReducer.getState();
      const { data } = await authService.forgotPassword(email);
      const forgetData = data;
      set({ forgetData, forgetPwdLoader: false });
      success(data?.message);
    } catch (err) {
      const { error } = useAlertReducer.getState();
      set({
        forgetPwdLoader: false,
      });
      error(err?.response?.data?.message ?? err.message);
    }
  },
}));

export default useLoginReducer;

import { create } from 'zustand';
import signupService from './signupService';
import useAlertReducer from '../../Login/reducer/AlertReducer';
import history from '../../../history';
const useSignupReducer = create((set) => ({
    isPlanLoading: false,
    plans: [],
    isSignupLoading: false,
    errorMessage: '',
    successMessage: '',
    clientSecretKey: '',
    setupIntentId: '',
    customerId: '',
    subscriptionId: '',
    subscriptionResponse: '',
    createSubscriptionLoading: false,
    isCouponLoading: false,
    couponErrorMsg: '',
    couponSuccessMsg: '',
    signupResponse: '',
    discountPrice: '',
    isCheckEmailLoading: false,
    subscriptionData: '',
    codeSuccessMsg: '',
    isCodeLoading: false,
    codeErrorMsg: '',
    emailFound: false,
    getPlans: async () => {
        try {
            set({
                isPlanLoading: true,
                couponSuccessMsg: '',
                couponErrorMsg: '',
                subscriptionData: '',
            });
            const { data } = await signupService.listPlans();
            const plans = data.plans;
            set({ plans, isPlanLoading: false, successMessage: '' });
            //   success(data && data.message);
        } catch (err) {
            console.log('err', err?.response?.data?.message);
            set({
                successMessage: '',
                errorMessage: err?.response?.data?.message ?? err?.message,
                isPlanLoading: false,
            });
        }
    },
    subscriptionSetupIntent: async (params, payload) => {
        try {
            set({
                // isSignupLoading: true,
                couponSuccessMsg: '',
                couponErrorMsg: '',
                subscriptionData: '',
            });
            const { data } = await signupService.subscriptionSetup(
                params,
                payload
            );
            set({
                successMessage: '',
                clientSecretKey: data?.data?.client_secret,
                setupIntentId: data?.data?.setupIntent,
                customerId: data?.data?.customerId,
                // isSignupLoading: false,
            });
        } catch (err) {
            console.log('err', err);
            set({
                successMessage: '',
                errorMessage: err?.response?.data?.message ?? err?.message,
                // isSignupLoading: false,
            });
        }
    },
    createSubscription: async (payload) => {
        try {
            set({
                successMessage: '',
                errorMessage: '',
                // createSubscriptionLoading: true,
                isSignupLoading: true,
            });
            const { data } = await signupService.subscription(payload);
            const { success } = useAlertReducer.getState();
            success(data && data.message);
            set({
                successMessage: '',
                subscriptionId: data.data.subscriptionId,
                subscriptionResponse: data.success,
                // createSubscriptionLoading: false,
                subscriptionData: data.data,
            });
        } catch (err) {
            const { error } = useAlertReducer.getState();
            error(err?.response?.data?.message ?? err.message);
            set({
                successMessage: '',
                errorMessage: err?.response?.data?.message ?? err?.message,
                // createSubscriptionLoading: false,
                isSignupLoading: false,
            });
        }
    },
    createUser: async (payload, openConfirmationModal, { toLogin }) => {
        try {
            set({
                successMessage: '',
                errorMessage: '',
                isSignupLoading: true,
            });
            // console.log('signup-payload', payload);
            // return;
            const { data } = await signupService.signup(payload);
            const { success } = useAlertReducer.getState();
            success(data && data.message);
            set({
                successMessage: data && data.message,
                errorMessage: '',
                isSignupLoading: false,
                signupResponse: data?.user,
            });
            if (openConfirmationModal) {
                openConfirmationModal(true);
            }
            if (toLogin) history.push('/login');
            sessionStorage.removeItem('signupData');
            sessionStorage.removeItem('signupTab');
        } catch (err) {
            const { error } = useAlertReducer.getState();
            error(err?.response?.data?.message ?? err.message);
            set({
                successMessage: '',
                errorMessage: err?.response?.data?.message ?? err?.message,
                isSignupLoading: false,
            });
        }
    },
    checkEmail: async (email) => {
        try {
            debugger;
            set({ isCheckEmailLoading: true });
            const { data } = await signupService.checkEmail(email);
            debugger;
            set({
                errorMessage: '',
                successMessage: data && data.message,
                isCheckEmailLoading: false,
                emailFound: data && data.success,
            });
        } catch (err) {
            debugger;
            set({
                successMessage: '',
                errorMessage: err?.response?.data?.message ?? err?.message,
                isCheckEmailLoading: false,
                emailFound: false,
            });
            const { error } = useAlertReducer.getState();
            error(err?.response?.data?.message ?? err.message);
        }
    },
    valdiateCouponCode: async (coupon) => {
        try {
            set({ isCouponLoading: true });
            const { data } = await signupService.checkCoupon(coupon);
            set({
                couponSuccessMsg: data.message,
                isCouponLoading: false,
                discountPrice: data.discountPrice,
                couponErrorMsg: '',
            });
        } catch (err) {
            set({
                couponErrorMsg:
                    err?.response?.data?.message ??
                    err?.message ??
                    err?.data?.message,
                errorMessage:
                    err?.response?.data?.message ??
                    err?.message ??
                    err?.data?.message,
                isCouponLoading: false,
                couponSuccessMsg: '',
                discountPrice: '',
            });
            // const { error } = useAlertReducer.getState();
            // error(err?.response?.data?.message ?? err.message);
        }
    },
    validateActivationCode: async (code) => {
        try {
            set({ isCodeLoading: true });
            const { data } = await signupService.checkActivationCode(code);
            set({
                codeSuccessMsg: data.message,
                isCodeLoading: false,
                codeErrorMsg: '',
            });
        } catch (err) {
            set({
                codeErrorMsg: err?.data?.message,
                errorMessage: err?.data?.message ?? err?.message,
                isCodeLoading: false,
                couponSuccessMsg: '',
            });
        }
    },
    resetActivation: async () => {
        set({ isCodeLoading: false, codeSuccessMsg: '', codeErrorMsg: '' });
    },
    resetCouponCode: async () => {
        set({
            isCouponLoading: false,
            couponErrorMsg: '',
            couponSuccessMsg: '',
        });
    },
}));

export default useSignupReducer;
